import {
  type LoggerConfiguration,
  type LogsEvent,
  datadogLogs,
} from "@datadog/browser-logs";
import {
  DATADOG_LOGGER_LEVEL,
  DATADOG_SITE,
  JOBBER_ONLINE_LOGGER,
} from "./constants";
import {
  abortError,
  adBlockerErrorAmplitude,
  chromeExtensionError,
  resizeObserverError,
  trackerBlockerError,
  typeError,
  undefinedScriptError,
} from "./ignoreFilters/ignoreFilters";

// eslint-disable-next-line max-statements
export function beforeSend(event: LogsEvent) {
  const { error, origin } = event;
  /**
   * Ignore logs that are not errors or are from the legacy code
   * but avoid filtering out logs from the datadogLogs.logger instance
   * Example: datadogLogs.logger.info("Test info message") or datadogLogs.logger.error("Test error message")
   */
  if (!error && origin !== "logger") {
    return false;
  }

  if (origin === "console") {
    return false;
  }

  if (resizeObserverError(event)) {
    return false;
  }

  if (typeError(event)) {
    return false;
  }

  if (abortError(event)) {
    return false;
  }

  if (chromeExtensionError(event)) {
    return false;
  }

  if (undefinedScriptError(event)) {
    return false;
  }

  if (trackerBlockerError(event)) {
    return false;
  }

  if (adBlockerErrorAmplitude(event)) {
    return false;
  }

  return true;
}

export function initDatadogLogging(loggerConfig: LoggerConfiguration = {}) {
  const { clientToken, env, service, version } =
    window._datadogErrorLoggingConfig || {};
  if (!clientToken) {
    return;
  }
  if (!didServiceInit()) {
    datadogLogs.init({
      clientToken,
      site: DATADOG_SITE,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service,
      version,
      env,
      beforeSend,
    });
  }

  if (!didLoggerInit()) {
    const mergedConfig: LoggerConfiguration = {
      level: DATADOG_LOGGER_LEVEL,
      ...loggerConfig,
    };
    datadogLogs.createLogger(JOBBER_ONLINE_LOGGER, mergedConfig);
  }
}

export function didServiceInit(): boolean {
  return datadogLogs.getInitConfiguration() != undefined;
}

export function didLoggerInit(): boolean {
  return datadogLogs.getLogger(JOBBER_ONLINE_LOGGER) != undefined;
}
